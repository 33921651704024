
import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';


const IcNotFound: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="370" height="168" viewBox="0 0 370 168" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M207.462 57.0872H189.492V38.6017H207.462V57.0872Z" fill="#53B374"/>
<path d="M207.462 94.058H189.492V75.5726H207.462V94.058Z" fill="#53B374"/>
<path d="M207.462 131.029H189.492V112.543H207.462V131.029Z" fill="#53B374"/>
<path d="M189.492 149.514V168H243.4V1.63086H126.601V19.6542L144.57 33.0561V20.1163H225.431V149.514H189.492Z" fill="#53B374"/>
</g>
<g opacity="0.5">
<path d="M180.508 57.087H162.539V38.6016H180.508V57.087Z" fill="#53B374"/>
<path d="M180.508 94.0579H162.539V75.5724H180.508V94.0579Z" fill="#53B374"/>
<path d="M180.508 131.029H162.539V112.543H180.508V131.029Z" fill="#53B374"/>
</g>
<rect x="126.601" y="149.243" width="116.799" height="18.7573" fill="#A9D9BA"/>
<rect x="126.601" y="149.243" width="116.799" height="18.7573" fill="#A9D9BA"/>
<rect x="126.601" y="168" width="166.369" height="17.9691" transform="rotate(-90 126.601 168)" fill="#A9D9BA"/>
<rect x="126.601" y="168" width="166.369" height="17.9691" transform="rotate(-90 126.601 168)" fill="#A9D9BA"/>
<rect x="75.96" y="166.369" width="166.369" height="17.9691" transform="rotate(-90 75.96 166.369)" fill="#A9D9BA"/>
<rect x="75.96" y="166.369" width="166.369" height="17.9691" transform="rotate(-90 75.96 166.369)" fill="#A9D9BA"/>
<rect x="352.031" y="168" width="166.369" height="17.9691" transform="rotate(-90 352.031 168)" fill="#A9D9BA"/>
<rect x="352.031" y="168" width="166.369" height="17.9691" transform="rotate(-90 352.031 168)" fill="#A9D9BA"/>
<rect y="93.7866" width="93.7864" height="17.9691" transform="rotate(-90 0 93.7866)" fill="#A9D9BA"/>
<rect y="93.7866" width="93.7864" height="17.9691" transform="rotate(-90 0 93.7866)" fill="#A9D9BA"/>
<rect x="276.07" y="95.4175" width="93.7864" height="17.9691" transform="rotate(-90 276.07 95.4175)" fill="#A9D9BA"/>
<rect x="276.07" y="95.4175" width="93.7864" height="17.9691" transform="rotate(-90 276.07 95.4175)" fill="#A9D9BA"/>
<rect x="93.9297" y="93.7866" width="93.9294" height="17.9418" transform="rotate(180 93.9297 93.7866)" fill="#A9D9BA"/>
<rect x="93.9297" y="93.7866" width="93.9294" height="17.9418" transform="rotate(180 93.9297 93.7866)" fill="#A9D9BA"/>
<rect x="370" y="95.4175" width="93.9294" height="17.9418" transform="rotate(180 370 95.4175)" fill="#A9D9BA"/>
<rect x="370" y="95.4175" width="93.9294" height="17.9418" transform="rotate(180 370 95.4175)" fill="#A9D9BA"/>
</svg>

  );
};

export default IcNotFound;