'use client';
import Link from 'next/link';

import { PageWrapper } from '@/components/layouts/Containers';

import { staticLinks } from '@/config/routingLinks';

import IcNotFound from '@/components/Icons/IcNotFound';
import styles from './styles/error.module.scss';

export default function NotFound() {
  const meta = {
    title: 'Страница не найдена',
  };

  return (
    <PageWrapper mainStyles={styles.main} meta={meta}>
      <div className={styles.wrapper}>
        <IcNotFound />
        <h1 className={styles.title}>Страница не найдена</h1>
        <p className={styles.description}>
          Возможно, у вас опечатка в адресе страницы,
          <br />
          или её просто не существует :(
        </p>
        <Link className={styles.button} href={staticLinks.main}>
          Перейти на главную
        </Link>
      </div>
    </PageWrapper>
  );
}
